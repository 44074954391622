import axios from "axios";
import { useState, useEffect } from "react";
import { useDemonWindow } from "../../hooks/openLevelWindow";
import Record from "./Record";
import { RecordsProps } from "../../types/props";
import getRandomInt from "../../utils/getRandomInt";
import "./LevelWindow.scss";

export default function LevelWindow() {
    const { windowOpen, closeWindow, windowData } = useDemonWindow();

    const [records, setRecords] = useState<Array<RecordsProps>>([]);

    useEffect(() => {
        try {
            const req = async () => {
                const res = await axios.get('./records.txt?' + getRandomInt(100, 999));
                const lines = res.data.split('\n');
                const result:Array<RecordsProps> = [];

                for(let i = 0; i < lines.length; i++) {
                    const params = lines[i].split(';');
                    if(windowData?.levelid != params[0]) continue;
                    result.push({ username: params[1], levelid: params[0], url: params[2] });
                }

                setRecords(result);
            }

            req();
        } catch(e) {
            console.log(e);
        }
    }, [windowData]);
    
    return (
        <dialog className="records-window" open={windowOpen}>
            <div className="records-window-content">
                <button onClick={() => closeWindow()}>Close</button>
                <div className="demon-window-content">
                    <h3>{windowData?.levelname} by {windowData?.author}</h3>
                    {
                        windowData?.description ? <p>{windowData?.description}</p> : ''
                    }
                    <ul className="records">
                        {
                            records.length > 0 ? records.map((record, index) => {
                                return <Record url={record.url} username={record.username} position={index+1} levelid={record.levelid} key={index} />
                            }) : 'No records yet'
                        }
                    </ul>
                </div>
            </div>
        </dialog>
    );
}