import axios from "axios";
import { useState, useEffect } from "react";
import Level from "../../components/Level/Level";
import { LevelProps } from "../../types/props";
import getRandomInt from "../../utils/getRandomInt";
import LevelWindow from "../../components/LevelWindow/LevelWindow";
import "./Demonlist.scss";

export default function Demonlist() {
    const [levels, setLevels] = useState<Array<LevelProps>>([]);

    useEffect(() => {
        try {
            const req = async () => {
                const res = await axios.get('./demons.txt?' + getRandomInt(100, 999));
                const lines = res.data.split('\n');
                let result:Array<LevelProps> = [];

                for(let i = 0; i < lines.length; i++) {
                    if(!lines[i].includes(';')) continue;

                    const params = lines[i].split(';');
                    result.push({ levelname: params[0], author: params[1], levelid: Number(params[2]), description: params[3] });
                }

                setLevels(result);
            }

            req();
        } catch(e) {
            console.log(e);
        }
    }, []);
    
    return (
        <main>
            <LevelWindow />
            <div className="container">
                <ul className="demonlist">
                    {
                        levels.length > 0 ? levels.map((level, index) => {
                            return <Level levelname={level.levelname} author={level.author} levelid={level.levelid} position={index+1} description={level.description ? level.description : ''} key={index+'demon'} />;
                        }) : 'No demons yet'
                    }
                </ul>
            </div>
        </main>
    );
}