import "../Home/Home.scss";

export default function Download() {
    return (
        <main>
            <div className="container">
                <div className="download" id="download" style={{marginTop: 200}}>
                    <div className="download-img">
                        <img src="./img/home/dwnl.png" alt="" />
                    </div>
                    <div className="download-text">
                        <h2>Download</h2>
                        <p>There's a whole other side to Geometry Dash, hidden from the ordinary. Explore it on our private server — where imagination reigns supreme, and the evolution never stops. Become a legend and gain universal recognition! See you at ReLegacy X!</p>
                        <div className="dwnl-btns">
                            <a href="./ReLegacyX.apk">Download APK v1.51</a>
                            <a href="./relegacy.ipa">Download IPA v1.4</a>
                        </div>
                    </div>
                </div>
            </div>
        </main>
    );
}